@use '../../styles/vars' as *;
@use '../../styles/mq' as mq;

.root {
    position: static;
    top: 0;
    left: 0;
    right: 0;
    height: calc(var(--sizing-core-8) + var(--sizing-core-1));
    padding: 0 var(--spacing-core-6);
    background-color: var(--color-background-primary-default);

    @include mq.mq($to: tablet) {
        display: none;
    }
}

.bar {
    display: flex;
    margin: 0 auto;
    max-width: calc($grid-width-max - var(--sizing-core-12));
    width: 100%;
    height: calc(
        var(--sizing-core-8) + var(--sizing-core-1) - var(--sizing-core-025)
    );
    border-bottom: var(--border-width-core-regular) solid
        var(--color-background-tertiary-default); /* color is not in palette */
}

.usps {
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 0 1 auto;
}

.caption {
    color: var(--color-action-primary-default);
    text-decoration: none;
    flex: 1 0 auto;
}

.title {
    height: 100%;

    /* important to overwrite some stuff in ah-features, remove it when ah-features is decommissioned */
    text-decoration: none !important;

    > * {
        color: inherit !important;
    }
}

.item {
    display: flex;
    align-items: center;
}

.list {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;
    min-width: 50px;
    gap: var(--spacing-core-4) 0;

    .item {
        flex-wrap: nowrap;
        margin: 0 var(--spacing-core-6) 0 0;
        height: inherit;
    }

    .link {
        color: var(--color-foreground-secondary-default);
    }
}

.icon {
    margin-right: var(--spacing-core-1);
    color: var(--color-action-primary-default);
}

.premium {
    .title,
    .icon {
        color: var(--color-action-premium-default);
    }
}

.links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    list-style: none;
    flex: 1 0 auto;

    .item {
        margin: 0 0 0 var(--spacing-core-6);
    }

    .link {
        color: var(--color-foreground-primary-default);
    }
}
