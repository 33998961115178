.root {
    padding: var(--spacing-core-4);
}

.content {
    gap: var(--spacing-core-4);
}

.subtext {
    color: var(--color-foreground-secondary-default);
}

.icon {
    min-width: var(--sizing-core-6);
}