.link {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    color: var(--color-foreground-primary-default);

    .text {
        color: var(--color-foreground-primary-default);
    }

    &:hover,
    &:focus {
        .text {
            text-decoration: underline;
        }
    }
}
