.button {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    color: var(--color-foreground-primary-default);

    .text {
        color: var(--color-foreground-primary-default);
    }

    &:hover,
    &:focus {
        .text {
            text-decoration: underline;
        }
    }
}

.menu {
    background-color: var(--color-background-primary-default);
    min-width: 359px;
    right: 0;
    z-index: 9999;
    border-radius: var(--border-radius-core-l);
    border: var(--border-width-core-regular) solid var(--color-background-tertiary-default);
    box-shadow:
                0 var(--sizing-core-4) var(--sizing-core-5) 0
                    var(--color-background-tertiary-pressed),
                0 0 var(--sizing-core-025) 0
                    var(--color-background-tertiary-pressed);
}